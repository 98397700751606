import { User } from "../../entities/user";
import i18n from "../../utils/i18n";
import { Event } from "../events/type";

export enum NotificationStatus {
  //Frontend only
  Asked = "asked",
  NotAsked = "notAsked",
  //Inner
  Failded = "failded",
  NotSent = "notSent",
  Sent = "sent",
  //Brevo
  Bounces = "bounces",
  HardBounces = "hardBounces",
  SoftBounces = "softBounces",
  Delivered = "delivered",
  Spam = "spam",
  Requests = "requests",
  Opened = "opened",
  Clicks = "clicks",
  Invalid = "invalid",
  Deferred = "deferred",
  Blocked = "blocked",
  Unsubscribed = "unsubscribed",
  Error = "error",
  LoadedByProxy = "loadedByProxy"
}

export enum NotificationType {
  Email = "email",
  InApp = "in_app"
}

/**
 * Note: In dev env, sended could be true with messageId null (because we don't send emails)
 */
export interface Notification {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  email: string;
  payload: ScheduleNotification;
  sended?: boolean;
  status: NotificationStatus;
  template?: string;
  messageId: string;
  eventIds?: string[];
  events?: Event[];
  userId?: string;
  user?: User;
  type: NotificationType;
  isRead?: boolean;
  isArchived?: boolean;
  category: NottificationSettingsCategoriesEnum;
}

export interface NotificationPaginated {
  nodes: Notification[];
  unreadCount: number;
}

export type TemplateNames =
  | "task_notification_en"
  | "task_notification_fr"
  | "creation_notification_en"
  | "creation_notification_fr"
  | string;

export interface ScheduleNotification
  extends NotificationBase,
    Partial<NotificationTask>,
    Partial<NotificationCreation> {}

export interface NotificationBase {
  createdAt?: Date;
  userId?: string;
  receiver?: string;
  email: string;
  subject: string;
  template: TemplateNames;
  sender: NotificationSender;
  style: NotificationStyle;
  unsubscribe?: NotificationUnsubscribe;
}

export interface NotificationSender {
  hostname: string;
  avatar?: string;
  name: string;
  postal_address: string;
  phone: string;
}

export interface NotificationStyle {
  logo: string;
  primaryColor: string;
  secondaryColor: string;
  icon: string;
}

export interface NotificationUnsubscribe {
  label: string;
  url: string;
}

export interface NotificationTask {
  assignation: boolean;
  task: TaskNotificationData;
  events?: {
    count: number;
    hasMore: boolean;
    list: NotificationEvents[];
  };
}

export interface TaskNotificationData {
  number: string;
  title: string;
  url: string;
  addLink: boolean;
  type?: string;
  company?: string;
  project?: string;
  briefs?: TaskCrm[];
  submissions?: TaskCrm[];
}

export interface TaskCrm {
  name: string;
}

export interface NotificationEvents {
  id: string;
  author: string;
  initials: string;
  date: string;
  description: string;
  avatar?: string;
}

export interface NotificationCreation {
  entityType: {
    configuration?: true;
    cofn?: true;
    project?: true;
    file?: true;
  };
  created: {
    name: string;
    creator: string;
    date: string;
    url: string;
    code?: number;
  };
  project?: {
    name: string;
    url: string;
  };
  company?: {
    name: string;
  };
  file?: {
    name: string;
  };
}

export interface EntityTypeMap {
  configuration?: true;
  cofn?: true;
  project?: true;
  file?: true;
}

export class NotificationException extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "NotificationException";
  }
}

export enum WebSocketMessages {
  Notification = "notification"
}

export enum NottificationSettingsCategoriesEnum {
  BRIEF_CREATION = "briefs.create",
  BRIEF_CREATION_PROJECTS = "briefs.create.projects",
  SUBMISSION_CREATION = "submissions.create",
  SUBMISSION_CREATION_PROJECTS = "submissions.create.projects",
  PROJECT_CREATION = "projects.create",
  PROJECT_CREATION_MANAGER = "projects.create.manager",
  PROJECT_CREATION_COMMENT = "projects.create.comment",
  PROJECT_CREATION_FILE = "projects.create.file",
  PROJECT_REMINDER_OVERDUE = "projects.create.reminder.overdue",
  PROJECT_REMINDER_UPCOMING = "projects.create.reminder.upcoming",
  PROJECT_UPDATE_STATUS = "projects.update.status",
  PROJECT_COMMENT_MENTION = "comment.create.user.projects",
  COMPANY_CREATION = "companies.create.manager",
  TASK_CREATION = "tasks.create",
  TASK_CREATION_MANAGER = "tasks.create.task_user",
  TASK_UPDATE_STATUS = "tasks.update.status",
  TASK_CREATION_COMMENT = "tasks.create.comment",
  TASK_REMINDER_OVERDUE = "tasks.create.reminder.overdue",
  TASK_REMINDER_UPCOMING = "tasks.create.reminder.upcoming",
  COMMENT_MENTION = "comment.create.user", // Phase 2
  TASK_REMINDER_USER = "tasks.create.reminder.user",
  TASK_COMMENT_MENTION = "comment.create.user.tasks",
  BRIEF_TIMEOUT_PRICE = "briefs.timeout.price",
  BRIEF_TIMEOUT_ERROR = "briefs.timeout.error",
  // SPECIFIC EMAIL TASK UPDATES NOTIFICATIONS
  TASK_UPDATE_DUEDATE = "tasks.update.duedate",
  TASK_UPDATE_DUEDATE_MANDATORY = "tasks.update.duedatemandatory",
  TASK_UPDATE_NAME = "tasks.update.name",
  TASK_UPDATE_TASK_TYPE = "tasks.update.tasktype",
  TASK_UPDATE_COMPANY = "tasks.update.company",
  TASK_UPDATE_PROJECT = "tasks.update.project",
  TASK_UPDATE_BRIEF = "tasks.update.brief",
  TASK_UPDATE_SUBMISSION = "tasks.update.submission",
  // SEMI SUPERVISED
  BRIEF_PRICE_CREATE = "briefs_price.create",
  BRIEF_PRICE_CREATE_OPERATION = "briefs_price.create.operation",
  BRIEF_PRICE_CREATE_MACHINE = "briefs_price.create.machine",
  //BRIEF_PRICE_CREATE_PROVIDER = "briefs_price.create.provider",
  BRIEF_PRICE_CREATE_COMMENT = "briefs_price.create.comment",
  BRIEF_PRICE_CREATE_LAYOUT = "briefs_price.create.layout",
  BRIEF_PRICE_CREATE_WASTETOTAL = "briefs_price.create.wastetotal",
  BRIEF_PRICE_CREATE_SETUPTIME = "briefs_price.create.setuptime",
  BRIEF_PRICE_CREATE_ROLLINGTIME = "briefs_price.create.rollingtime",
  BRIEF_PRICE_CREATE_EXTRA_COSTS_COMMENT = "briefs_price.create.extracostscomment"
}

export type NotificationAllDto = {
  userId: string;
  category?: string;
};

export const nottificationSettingsCategories = Object.values(
  NottificationSettingsCategoriesEnum
).map((item) => item.toLowerCase());

export interface NotificationFilter {
  category: string;
}

export const intialNotificationFilters: NotificationFilter = {
  category: ""
};

const briefNotificationOptions = () => {
  return (
    [
      {
        value: NottificationSettingsCategoriesEnum.BRIEF_CREATION_PROJECTS,
        label: i18n.t(
          `backoffice:notifications.inApp.category.${NottificationSettingsCategoriesEnum.BRIEF_CREATION_PROJECTS}.filter`
        )
      }
    ]
      // filter empty values
      .filter((option) => option?.value)
  );
};

const submissionNotificationOptions = () => {
  return (
    [
      {
        value: NottificationSettingsCategoriesEnum.SUBMISSION_CREATION_PROJECTS,
        label: i18n.t(
          `backoffice:notifications.inApp.category.${NottificationSettingsCategoriesEnum.BRIEF_CREATION_PROJECTS}.filter`
        )
      }
    ]
      // filter empty values
      .filter((option) => option?.value)
  );
};

const projectNotificationOptions = () => {
  return [
    {
      value: NottificationSettingsCategoriesEnum.PROJECT_CREATION_MANAGER,
      label: i18n.t(
        `backoffice:notifications.inApp.category.${NottificationSettingsCategoriesEnum.PROJECT_CREATION_MANAGER}.filter`
      )
    },
    {
      value: NottificationSettingsCategoriesEnum.PROJECT_CREATION_COMMENT,
      label: i18n.t(
        `backoffice:notifications.inApp.category.${NottificationSettingsCategoriesEnum.PROJECT_CREATION_COMMENT}.filter`
      )
    },
    {
      value: NottificationSettingsCategoriesEnum.BRIEF_CREATION_PROJECTS,
      label: i18n.t(
        `backoffice:notifications.inApp.category.${NottificationSettingsCategoriesEnum.BRIEF_CREATION_PROJECTS}.filter`
      )
    },
    {
      value: NottificationSettingsCategoriesEnum.SUBMISSION_CREATION_PROJECTS,
      label: i18n.t(
        `backoffice:notifications.inApp.category.${NottificationSettingsCategoriesEnum.SUBMISSION_CREATION_PROJECTS}.filter`
      )
    },

    {
      value: NottificationSettingsCategoriesEnum.PROJECT_CREATION_FILE,
      label: i18n.t(
        `backoffice:notifications.inApp.category.${NottificationSettingsCategoriesEnum.PROJECT_CREATION_FILE}.filter`
      )
    },
    {
      value: NottificationSettingsCategoriesEnum.PROJECT_UPDATE_STATUS,
      label: i18n.t(
        `backoffice:notifications.inApp.category.${NottificationSettingsCategoriesEnum.PROJECT_UPDATE_STATUS}.filter`
      )
    },
    {
      value: NottificationSettingsCategoriesEnum.PROJECT_REMINDER_OVERDUE,
      label: i18n.t(
        `backoffice:notifications.inApp.category.${NottificationSettingsCategoriesEnum.PROJECT_REMINDER_OVERDUE}.filter`
      )
    },
    {
      value: NottificationSettingsCategoriesEnum.PROJECT_REMINDER_UPCOMING,
      label: i18n.t(
        `backoffice:notifications.inApp.category.${NottificationSettingsCategoriesEnum.PROJECT_REMINDER_UPCOMING}.filter`
      )
    },
    {
      value: NottificationSettingsCategoriesEnum.PROJECT_COMMENT_MENTION,
      label: i18n.t(
        `backoffice:notifications.inApp.category.${NottificationSettingsCategoriesEnum.PROJECT_COMMENT_MENTION}.filter`
      )
    }
  ];
};

const taskNotificationOptions = () => {
  return [
    {
      value: NottificationSettingsCategoriesEnum.TASK_CREATION_MANAGER,
      label: i18n.t(
        `backoffice:notifications.inApp.category.${NottificationSettingsCategoriesEnum.TASK_CREATION_MANAGER}.filter`
      )
    },
    {
      value: NottificationSettingsCategoriesEnum.TASK_CREATION_COMMENT,
      label: i18n.t(
        `backoffice:notifications.inApp.category.${NottificationSettingsCategoriesEnum.TASK_CREATION_COMMENT}.filter`
      )
    },
    {
      value: NottificationSettingsCategoriesEnum.TASK_UPDATE_STATUS,
      label: i18n.t(
        `backoffice:notifications.inApp.category.${NottificationSettingsCategoriesEnum.TASK_UPDATE_STATUS}.filter`
      )
    },
    {
      value: NottificationSettingsCategoriesEnum.TASK_REMINDER_OVERDUE,
      label: i18n.t(
        `backoffice:notifications.inApp.category.${NottificationSettingsCategoriesEnum.TASK_REMINDER_OVERDUE}.filter`
      )
    },
    {
      value: NottificationSettingsCategoriesEnum.TASK_REMINDER_UPCOMING,
      label: i18n.t(
        `backoffice:notifications.inApp.category.${NottificationSettingsCategoriesEnum.TASK_REMINDER_UPCOMING}.filter`
      )
    },
    {
      value: NottificationSettingsCategoriesEnum.TASK_COMMENT_MENTION,
      label: i18n.t(
        `backoffice:notifications.inApp.category.${NottificationSettingsCategoriesEnum.TASK_COMMENT_MENTION}.filter`
      )
    }
  ];
};

export const notificationGroupedOptions = () => {
  return [
    {
      label: i18n.t("backoffice:menu.projects"),
      options: projectNotificationOptions()
    },
    {
      label: i18n.t("backoffice:menu.tasks"),
      options: taskNotificationOptions()
    }
  ];
};
