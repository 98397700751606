import { Box, Typography } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import ArchiveIcon from "@material-ui/icons/Archive";
import React from "react";
import { useTranslation } from "react-i18next";
import { MyButton } from "./MyButton";
import { MyDialogProps, MyDialog } from "./MyDialog";
import { MyTypography } from "./MyTypography";

export interface PreventArchivedDialogProps extends MyDialogProps {
  onConfirm?: () => void;
  actionTitle?: string;
  title?: string;
  content?: string;
  isActions?: boolean;
  titleIcon?: JSX.Element;
  cancelTitle?: string;
}

export const PreventArchivedDialogGeneric: React.FC<PreventArchivedDialogProps> = ({
  onConfirm,
  actionTitle,
  cancelTitle,
  title,
  content,
  isActions = false,
  titleIcon,
  ...props
}) => {
  const { t: tCommon } = useTranslation("common");
  return (
    <MyDialog
      fullScreen={false}
      titleContent={
        <MyTypography
          leftIcon={titleIcon ?? <ArchiveIcon color="error" />}
          variant="h6"
        >
          {title ?? tCommon("preventDeleteTitle")}
        </MyTypography>
      }
      {...props}
      style={{ borderRadius: "5px" }}
      content={
        <>
          <Box px={2}>
            <Typography
              component={"span"}
              dangerouslySetInnerHTML={{
                __html: content ?? (tCommon("preventDeleteContent") as string)
              }}
            />
          </Box>
          {isActions && (
            <Box display="flex" justifyContent="flex-end" mt={3}>
              <MyButton
                onClick={(e) =>
                  props.onClose && props.onClose(e, "backdropClick")
                }
              >
                {cancelTitle ?? tCommon("cancel")}
              </MyButton>
              <Box ml={2}>
                <MyButton
                  style={{ color: "white", backgroundColor: red[400] }}
                  value="contained"
                  onClick={onConfirm}
                >
                  {actionTitle ?? tCommon("preventDeleteConfirm")}
                </MyButton>
              </Box>
            </Box>
          )}
        </>
      }
    />
  );
};
