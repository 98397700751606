import i18n from "../../utils/i18n";

export enum CadStatus {
  REQUESTED = "Requested",
  RECEIVED = "Received",
  FAILED = "Failed"
}

export const ALICIA_TIMEOUT = 45000; // 45 seconds timeout

export enum PackingTypeName {
  DEFAULT = "default",
  MASTER_CASE = "master_case",
  BUNDLE = "bundle"
}

export const packingTypeOptions = () => [
  {
    label: i18n.t("configurator:packingType.options.default"),
    value: PackingTypeName.DEFAULT
  },
  {
    label: i18n.t("configurator:packingType.options.master_case"),
    value: PackingTypeName.MASTER_CASE
  },
  {
    label: i18n.t("configurator:packingType.options.bundle"),
    value: PackingTypeName.BUNDLE
  }
];

export const packingTypeLabel = (
  value?: PackingTypeName
): string | undefined => {
  if (!value) return;
  switch (value) {
    case PackingTypeName.DEFAULT:
      return i18n.t("briefDetail:logistics.packingType.default");
    case PackingTypeName.MASTER_CASE:
      return i18n.t("briefDetail:logistics.packingType.master_case");
    case PackingTypeName.BUNDLE:
      return i18n.t("briefDetail:logistics.packingType.bundle");
  }
};

export enum PalletTypeName {
  STANDARD = "standard",
  CUSTOM = "custom"
}

export const palletTypeOptions = () => [
  {
    label: i18n.t("configurator:palletType.options.standard"),
    value: PalletTypeName.STANDARD
  },
  {
    label: i18n.t("configurator:palletType.options.custom"),
    value: PalletTypeName.CUSTOM
  }
];

export const palletTypeLabel = (value?: PalletTypeName): string | undefined => {
  if (!value) return;
  switch (value) {
    case PalletTypeName.STANDARD:
      return i18n.t("briefDetail:logistics.palletType.standard");
    case PalletTypeName.CUSTOM:
      return i18n.t("briefDetail:logistics.palletType.custom");
  }
};

export const cadOptions = () => {
  return [
    {
      label: i18n.t("common:received"),
      style: { background: "rgb(237, 250, 245)", color: "rgb(17, 175, 118)" },
      value: CadStatus.RECEIVED
    },
    {
      label: i18n.t("common:requested"),
      style: { background: "#EAEFFF", color: "#2E5BFF" },
      value: CadStatus.REQUESTED
    }
  ];
};

export enum LayoutTypeName {
  ROW_COLUMN = "row_column",
  COMPLEX = "complex"
}

export const layoutTypeNameOptions = () => [
  {
    label: i18n.t("configurator:layoutType.options.rowColumn"),
    value: LayoutTypeName.ROW_COLUMN
  },
  {
    label: i18n.t("configurator:layoutType.options.complex"),
    value: LayoutTypeName.COMPLEX
  }
];

export const layoutTypeLabel = (layout: LayoutTypeName) => {
  switch (layout) {
    case LayoutTypeName.ROW_COLUMN:
      return i18n.t("configurator:layoutType.options.rowColumn");
    case LayoutTypeName.COMPLEX:
      return i18n.t("configurator:layoutType.options.complex");
    default:
      break;
  }
};
