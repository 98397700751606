import axios, { AxiosError } from "axios";
import { Brief, BriefElement, BriefWithRelations } from "../../entities/brief";
import { apiSlice } from "../../utils/api/api";
import { AppAddSnackbar } from "../../reducers/app/action";
import i18n from "../../utils/i18n";
import { sendErrorNotification } from "../../utils/request/error_handler";
import {
  AssignProject,
  BriefOptimiseQuoteDTO,
  BriefOptimiseQuoteSearchDecompressDTO,
  BriefOptimiseQuoteSearchDTO,
  BriefPagination,
  UpdateBriefStatus
} from "./types";
import { dynamicUrl } from "../../utils/function/dynamicUrl";
import { PageMetaDto } from "../common/types";
import { transformBrief } from "./transform";
import {
  BriefPriceComment,
  PredictionRequest
} from "../../entities/predictionRequest";
import { BriefConfiguratorValues } from "../../reducers/briefConfigurator/reducer";
import { BriefElementConfiguratorValues } from "../../reducers/briefElementConfigurator/reducer";
import { Task } from "../tasks/type";
import { DecorationCount } from "../../entities/decorationsCount";
import { PredictionRequestComputed } from "../../entities/predictionRequestComputed";
import {
  OptimiseQuoteComment,
  OptimiseQuoteEditFields
} from "../../pages/Brief/Detail/utils/quotation.type";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: [
    "Briefs",
    "Brief",
    "BriefDetails",
    "BriefElementInputLayout",
    "BriefPredictionProcess",
    "BriefValueAnalysis",
    "BriefPredictionPanel"
  ]
});

interface RecomputeBrief {
  id: string;
  decorationsCount: DecorationCount[];
  save: boolean;
}

export const extendedBriefApiSlice = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getBriefs: builder.query<BriefPagination, Partial<PageMetaDto>>({
      query: ({
        page = 1,
        limit = 25,
        status,
        sort,
        price,
        search,
        createdBy
      }) =>
        dynamicUrl("/briefs", {
          page,
          limit,
          sort,
          status,
          price,
          search,
          createdBy
        }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: "Briefs" as const, id })),
              { type: "Briefs", id: "PARTIAL-LIST" }
            ]
          : [{ type: "Briefs", id: "PARTIAL-LIST" }],
      transformResponse: (responseData: BriefPagination) => {
        return transformBrief(responseData);
      }
    }),
    editBriefName: builder.mutation<Brief, Partial<Brief>>({
      query(data) {
        const { id, name } = data;
        return {
          url: `/briefs/${id}`,
          method: "PATCH",
          body: { name }
        };
      },
      invalidatesTags: (_, __, data) => [
        { type: "Briefs", id: "PARTIAL-LIST" }
      ],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            new AppAddSnackbar(i18n.t("saga:update-success"), "success")
          );
        } catch (err) {
          dispatch(
            sendErrorNotification(
              err as AxiosError,
              i18n.t("saga:update-failed")
            )
          );
        }
      }
    }),
    assignProject: builder.mutation<void, AssignProject>({
      query({ brief, projectId }) {
        return {
          url: `/briefs/${brief.id}/project`,
          method: "PATCH",
          body: { projectId }
        };
      },
      invalidatesTags: () => [{ type: "Briefs", id: "PARTIAL-LIST" }],
      async onQueryStarted(arg, { dispatch }) {
        try {
          dispatch(
            new AppAddSnackbar(i18n.t("saga:update-success"), "success")
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed")
            )
          );
        }
      }
    }),
    updateStatus: builder.mutation<void, UpdateBriefStatus>({
      query({ briefId, statusId }) {
        return {
          url: `/briefs/${briefId}`,
          method: "PATCH",
          body: { statusId }
        };
      },
      invalidatesTags: () => [{ type: "Briefs", id: "PARTIAL-LIST" }],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          dispatch(
            new AppAddSnackbar(i18n.t("saga:update-success"), "success")
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed")
            )
          );
        }
      }
    }),
    getBriefCountByStatus: builder.query<number, string>({
      query(statusId) {
        return {
          url: `/briefs/${statusId}/status-count`,
          method: "GET"
        };
      }
    }),
    getBrief: builder.query<BriefWithRelations, Partial<Brief>>({
      query({ id }) {
        return {
          url: `/briefs/${id}`,
          method: "GET"
        };
      },
      providesTags: (result) => [{ type: "Brief", id: result?.id }]
    }),
    getBriefDetails: builder.query<BriefWithRelations, Partial<Brief>>({
      query({ id }) {
        return {
          url: `/briefs/${id}/details`,
          method: "GET"
        };
      },
      transformResponse: (responseData: BriefWithRelations) => {
        let comments;
        const priceComments =
          responseData?.predictionRequest?.briefPriceComments;

        if (priceComments && priceComments?.length > 0) {
          comments = priceComments?.reduce(
            (comments, comment: BriefPriceComment): any => {
              const key = comment.isValueAnalysis
                ? comment.key
                : `${comment.key}_${comment.position}_${comment.partNumber}`;
              const process = comment.process.toString();

              if (comment.isValueAnalysis) {
                return {
                  ...comments,
                  valueAnalysisComments: {
                    ...comments.valueAnalysisComments,
                    [key]: {
                      comment: comment?.comment?.content,
                      position: comment.position,
                      field: comment.key,
                      process: comment.process,
                      user: comment?.comment?.user,
                      isValueAnalysis: comment.isValueAnalysis
                    }
                  }
                };
              } else {
                const currentProcess =
                  comments?.processComments?.[process] ?? {};

                return {
                  ...comments,
                  processComments: {
                    ...comments.processComments,
                    [process]: {
                      ...currentProcess,
                      [key]: {
                        comment: comment?.comment?.content,
                        position: comment.position,
                        field: comment.key,
                        process: comment.process,
                        user: comment?.comment?.user,
                        isValueAnalysis: comment.isValueAnalysis,
                        partNumber: comment.partNumber
                      }
                    }
                  }
                };
              }
            },
            {
              processComments: {} as Record<
                string,
                Record<string, OptimiseQuoteComment[]>
              >,
              valueAnalysisComments: {} as Record<
                string,
                OptimiseQuoteComment[]
              >
            }
          );
        }

        return { ...responseData, ...comments };
      },
      providesTags: (result) => [{ type: "BriefDetails", id: result?.id }]
    }),
    getBriefElementInputLayout: builder.query<
      Pick<BriefElement, "inputLayout">,
      { id: string; elementId: string }
    >({
      query({ id, elementId }) {
        return {
          url: `/briefs/${id}/elements/${elementId}/input-layout`,
          method: "GET"
        };
      },
      providesTags: (_, __, { elementId }) => [
        { type: "BriefElementInputLayout", id: elementId }
      ]
    }),
    getBriefPredictionProcess: builder.query<
      PredictionRequestComputed,
      { id: string; position: number; edit?: boolean }
    >({
      query({ id, position, edit }) {
        return {
          url: edit
            ? `/briefs/${id}/process/${position}?edit=true`
            : `/briefs/${id}/process/${position}`,
          method: "GET"
        };
      },
      providesTags: (_, __, { id }) => [{ type: "BriefPredictionProcess", id }],
      transformResponse: (responseData: PredictionRequestComputed) => {
        const predictionRequest = responseData.predictionRequest;

        let optimiseQuoteActions;
        if (predictionRequest?.optimiseQuoteActions) {
          optimiseQuoteActions = Object.entries(
            predictionRequest?.optimiseQuoteActions
          )?.reduce((operationSequences, [process, actions]) => {
            const actionProcess = actions?.reduce((acc, action) => {
              if (process !== "valueAnalysis") {
                const field = action?.field;
                const position = action?.index;
                const partNumber = action?.partNumber;
                const keyAccess = `${field}_${position}_${partNumber}`;
                if (action?.locked) {
                  action.prevValue = undefined;
                  acc[keyAccess] = action;
                }
              }
              return acc;
            }, {} as Record<string, OptimiseQuoteEditFields>);

            operationSequences[process] = actionProcess;
            return operationSequences;
          }, {} as Record<string, Record<string, OptimiseQuoteEditFields>>);
        }

        return { ...responseData, optimiseQuoteActions };
      }
    }),
    getBriefValueAnalysis: builder.query<PredictionRequest, { id: string }>({
      query({ id }) {
        return {
          url: `/briefs/${id}/value-analysis`,
          method: "GET"
        };
      },
      providesTags: (_, __, { id }) => [{ type: "BriefValueAnalysis", id }],
      transformResponse: (responseData: PredictionRequest) => {
        const predictionRequest = responseData;
        let valueAnalysisActions;
        if (predictionRequest?.optimiseQuoteActions) {
          valueAnalysisActions = Object.entries(
            predictionRequest?.optimiseQuoteActions
          )?.reduce((operationSequences, [process, actions]) => {
            const actionProcess = actions?.reduce((acc, action) => {
              if (process === "valueAnalysis") {
                const keyAccess = `${action.field}`;
                if (action?.locked || action?.isExtraCost) {
                  action.prevValue = undefined;
                  if (action?.isExtraCost) {
                    action.isAdded = undefined;
                  }
                  acc[keyAccess] = action;
                }
              }
              return acc;
            }, {} as Record<string, OptimiseQuoteEditFields>);

            operationSequences[process] = actionProcess;
            return operationSequences;
          }, {} as Record<string, Record<string, OptimiseQuoteEditFields>>);
        }

        return { ...responseData, valueAnalysisActions } as PredictionRequest;
      }
    }),
    getBriefPrediction: builder.query<BriefWithRelations, Partial<Brief>>({
      query({ id }) {
        return {
          url: `/briefs/${id}/prediction-panel`,
          method: "GET"
        };
      },
      providesTags: (result) => [
        { type: "BriefPredictionPanel", id: result?.id }
      ]
    }),
    getBriefMultiTask: builder.query<Partial<Task>[], Partial<Brief>>({
      query({ id }) {
        return {
          url: `/briefs/${id}/multi-tasks`,
          method: "GET"
        };
      }
    }),
    createOneBrief: builder.mutation<
      BriefWithRelations,
      BriefConfiguratorValues & {
        briefElements: Array<Partial<BriefElementConfiguratorValues>>;
      }
    >({
      query(body) {
        return {
          url: `/briefs`,
          method: "POST",
          body: body
        };
      }
    }),
    recomputeBriefQuantity: builder.mutation<
      BriefWithRelations,
      RecomputeBrief
    >({
      query({ id, decorationsCount, save }) {
        return {
          url: `/briefs/${id}/new-prediction?save=true`,
          method: "POST",
          body: { decorationsCount, save }
        };
      }
    }),
    optimiseQuoteRecompute: builder.mutation<any, BriefOptimiseQuoteDTO>({
      query(dto) {
        const { briefId, briefPriceId, type, ...body } = dto;
        return {
          url: `/briefs/${briefId}/brief-price/${briefPriceId}/recompute?type=${type}`,
          method: "POST",
          body: body
        };
        // As a result should invalidate the briefDetail
      },
      invalidatesTags: (_, __, { briefId }) => [
        { type: "BriefPredictionProcess", id: briefId },
        { type: "BriefValueAnalysis", id: briefId }
      ],

      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data?._errorMessage) {
            return;
          }

          // Update the brief detail
          dispatch(
            extendedBriefApiSlice.util.updateQueryData(
              `getBriefDetails`,
              {
                id: arg.briefId
              },
              (draft: any) => {
                draft.predictionRequest.version =
                  data.predictionRequest.version;
              }
            )
          );

          dispatch(
            new AppAddSnackbar(
              i18n.t("briefDetail:optimiseQuote.recalculating.success"),
              "success"
            )
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("briefDetail:optimiseQuote.recalculating.error")
            )
          );
        }
      }
    }),
    optimiseQuoteSearch: builder.mutation<any, BriefOptimiseQuoteSearchDTO>({
      query(dto) {
        const { briefId, optimise, process, comments } = dto;
        return {
          url: `/briefs/${briefId}/process/${process}/search`,
          method: "POST",
          body: { search: Object.values(optimise), comments }
        };
        // As a result should invalidate the briefDetail
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            new AppAddSnackbar(
              i18n.t("briefDetail:optimiseQuote.recalculating.success"),
              "success"
            )
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed")
            )
          );
        }
      }
    }),
    decompressSequence: builder.mutation<
      any,
      BriefOptimiseQuoteSearchDecompressDTO
    >({
      query(dto) {
        const { briefId, process } = dto;
        return {
          url: `/briefs/${briefId}/process/${process}/decompress`,
          method: "POST",
          body: dto
        };
      }
    }),
    updatePredictionRequest: builder.mutation<
      PredictionRequest,
      Partial<PredictionRequest>
    >({
      query(dto) {
        const { id, ...patch } = dto;
        return {
          url: `/prediction-requests/${id}`,
          method: "PATCH",
          body: patch
        };
      },
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(
            new AppAddSnackbar(i18n.t("saga:update-success"), "success")
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed")
            )
          );
        }
      },
      invalidatesTags: (_, __, data) => [
        { type: "Brief", id: data?.briefId },
        { type: "BriefDetails", id: data?.briefId },
        { type: "BriefValueAnalysis", id: data?.briefId },
        { type: "BriefPredictionPanel", id: data?.briefId }
      ]
    }),
    aliciaPreTreatment: builder.mutation<boolean, string>({
      query(id) {
        return {
          url: `/briefs/${id}/pre-treatment`,
          method: "POST"
        };
      },
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          /*  dispatch(
            new AppAddSnackbar(i18n.t("saga:update-success"), "success")
          ); */
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed")
            )
          );
        }
      }
    }),
    archiveBrief: builder.mutation<void, string>({
      query(id) {
        return {
          url: `/briefs/${id}/archive`,
          method: "DELETE"
        };
      },
      invalidatesTags: (_, __, data) => [
        { type: "Briefs", id: "PARTIAL-LIST" }
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        try {
          dispatch(
            new AppAddSnackbar(i18n.t("saga:delete-success"), "success")
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:delete-failed")
            )
          );
        }
      }
    })
  }),
  overrideExisting: false
});

// injectEndpoints to avoid duplicate slice
export const {
  useGetBriefsQuery,
  useGetBriefQuery,
  useGetBriefDetailsQuery,
  useGetBriefPredictionProcessQuery,
  useGetBriefElementInputLayoutQuery,
  useGetBriefValueAnalysisQuery,
  useEditBriefNameMutation,
  useUpdateStatusMutation,
  useAssignProjectMutation,
  usePrefetch: useBriefsPrefetch,
  useLazyGetBriefsQuery,
  useGetBriefCountByStatusQuery,
  useLazyGetBriefQuery,
  useCreateOneBriefMutation,
  useGetBriefPredictionQuery,
  useUpdatePredictionRequestMutation,
  useGetBriefMultiTaskQuery,
  useRecomputeBriefQuantityMutation,
  useOptimiseQuoteRecomputeMutation,
  useOptimiseQuoteSearchMutation,
  useDecompressSequenceMutation,
  useAliciaPreTreatmentMutation,
  useLazyGetBriefPredictionProcessQuery,
  useArchiveBriefMutation
} = extendedBriefApiSlice;

export const onDecompressSequenceOnChange = (
  briefId: string,
  process: any,
  signature: string,
  url: string,
  part?: number
) =>
  axios.post(
    `/briefs/${briefId}/process/${process}/decompress`,
    {
      signature,
      part
    },
    {
      baseURL: url || "/api",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    }
  );
