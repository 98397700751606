import {
  array,
  boolean,
  mixed,
  number,
  NumberSchema,
  object,
  string
} from "yup";
import { ConfiguratorInputOptionsResult } from "../../../../../../reducers/configurator-input-options/selector";
import { ConfiguratorInputNames } from "../../../../../../reducers/configurator-inputs/constant";
import {
  applyInputOptionsLogic,
  applyInputOptionsLogicBoolean,
  applyInputOptionsLogicNumber,
  applyInputOptionsLogicObject,
  applyInputOptionsLogicString,
  setYupLocale
} from "../../../../../../utils/yup";
import { LayoutSchemaWithOptions } from "./layouCount.schema";
import { CustomError } from "../../../../../../components/common/form/common/MyFormHelperText";
import i18n from "../../../../../../utils/i18n";
import { jsonTranslator } from "../../../../../../utils/function/jsonTranslator";
import { LayoutTypeName } from "../../../../../../reducers/briefs/constant";

setYupLocale();

export const PackagingSchema = {
  productCategoryId: string().required(),
  productId: string().required(),
  lengthDimension: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.LENGTH_DIMENSION,
    number().min(0)
  ),
  widthDimension: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.WIDTH_DIMENSION,
    number().min(0)
  ),
  heightDimension: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.HEIGHT_DIMENSION,
    number().min(0)
  ),
  widthGluedFlap: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.WIDTH_GLUED_FLAP,
    number().min(0)
  ),
  widthInsideFlap: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.WIDTH_INSIDE_FLAP,
    number().min(0)
  ),
  specifyRequest: applyInputOptionsLogicString(
    ConfiguratorInputNames.SPECIFY_REQUEST,
    string()
  ),
  totalRuleLength: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.TOTAL_RULE_LENGTH,
    number().min(0)
  ),
  distanceBetweenLayoutHeight: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.DISTANCE_BETWEEN_LAYOUT_HEIGHT,
    number().min(0).max(100)
  ),
  distanceBetweenLayoutWidth: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.DISTANCE_BETWEEN_LAYOUT_WIDTH,
    number().min(0).max(100)
  ),
  bagPieces: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.BAG_PIECES,
    number()
  ),
  addWindow: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.ADD_WINDOW,
    boolean().default(false)
  ),
  knifeToKnifeX: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.KNIFE_TO_KNIFE_X,
    number().min(0)
  ),
  knifeToKnifeY: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.KNIFE_TO_KNIFE_Y,
    number().min(0)
  ),
  layoutTotalRuleLength: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.LAYOUT_TOTAL_RULE_LENGTH,
    number().min(0)
  ),
  layoutType: applyInputOptionsLogicString(
    ConfiguratorInputNames.LAYOUT_TYPE,
    string().oneOf([LayoutTypeName.ROW_COLUMN, LayoutTypeName.COMPLEX]),
    string()
  ),
  blanksTotal: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.BLANKS_TOTAL,
    number().min(0)
  ),
  customLayout: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.CUSTOM_LAYOUT,
    boolean().default(false)
  ),
  dieCutToolExist: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.DIECUT_TOOL_EXIST,
    boolean().default(false)
  ),
  dieCutToolLayoutCount: applyInputOptionsLogicObject(
    ConfiguratorInputNames.DIECUT_TOOL_LAYOUT_COUNT,
    LayoutSchemaWithOptions
  ),
  dieCutToolForce: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.DIECUT_TOOL_FORCE,
    boolean().default(false)
  ),
  dieCutToolMachineIds: applyInputOptionsLogicObject(
    ConfiguratorInputNames.DIECUT_TOOL_MACHINE_IDS,
    (required: boolean) =>
      array()
        .nullable(!required)
        .of(string())
        .min(required ? 1 : 0)
  ),
  addTearStrip: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.ADD_TEAR_STRIP,
    boolean().default(false)
  ),
  addSealingStrip: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.ADD_SEALING_STRIP,
    boolean().default(false)
  ),
  addMecanisation: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.ADD_MECANISATION,
    boolean().default(false)
  ),
  dividersLengthBigElement: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.DIVIDERS_LENGTH_BIG_ELEMENT,
    number()
  ),
  dividersLengthSmallElement: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.DIVIDERS_SPACE_BETWEEN_NOTCHES_SMALL_ELEMENT,
    number()
  ),
  dividersHeightElement: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.DIVIDERS_HEIGHT_ELEMENT,
    number()
  ),
  dividersNotchBorderDistance: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.DIVIDERS_NOTCH_BORDER_DISTANCE,
    number()
  ),
  dividersSpaceBetweenNotchesBigElement: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.DIVIDERS_SPACE_BETWEEN_NOTCHES_BIG_ELEMENT,
    number()
  ),
  dividersSpaceBetweenNotchesSmallElement: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.DIVIDERS_SPACE_BETWEEN_NOTCHES_SMALL_ELEMENT,
    number()
  ),
  windowWidth: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.WINDOW_WIDTH,
    number().min(0)
  ),
  windowHeight: applyInputOptionsLogicNumber(
    ConfiguratorInputNames.WINDOW_HEIGHT,
    number().min(0)
  ),
  windowWantProtection: applyInputOptionsLogicBoolean(
    ConfiguratorInputNames.WINDOW_HEIGHT,
    boolean().default(false)
  ),
  windowId: applyInputOptionsLogic(ConfiguratorInputNames.WINDOW_ID, string()),
  specifyWindow: applyInputOptionsLogicString(
    ConfiguratorInputNames.SPECIFY_WINDOW,
    string()
  ),
  external: mixed().when(
    "$configuratorInputOptions",
    (inputOptions: ConfiguratorInputOptionsResult) => {
      const externalShape: Record<string, NumberSchema> = {};
      const externalInputOptions = Object.values(inputOptions).filter(
        (option) => option.external
      );
      for (const option of externalInputOptions) {
        const name = option.name;
        const { required, options } = option;
        externalShape[name] = number();

        if (options?.min) {
          externalShape[name] = externalShape[name].min(
            options.min,
            (params): any => {
              const errorMessage = i18n.t("yup:number.min", params);
              if (!options?.minMessage) return errorMessage;
              return new CustomError(
                errorMessage,
                jsonTranslator(options.minMessage, i18n.language)
              );
            }
          );
        }

        if (options?.max) {
          externalShape[name] = externalShape[name].max(
            options.max,
            (params): any => {
              const errorMessage = i18n.t("yup:number.max", params);
              if (!options?.maxMessage) return errorMessage;
              return new CustomError(
                errorMessage,
                jsonTranslator(options.maxMessage, i18n.language)
              );
            }
          );
        }

        if (required) {
          externalShape[name] = externalShape[name].required();
        }
      }
      return object().shape(externalShape);
    }
  )
};
