import { AxiosError, AxiosRequestConfig } from "axios";
import { Brief } from "../../entities/brief";
import { Project } from "../../entities/project";
import { Submission } from "../../entities/submission";
import { AppAddSnackbar } from "../../reducers/app/action";
import { apiSlice } from "../../utils/api/api";
import { dynamicUrl } from "../../utils/function/dynamicUrl";
import i18n from "../../utils/i18n";
import { Request } from "../../utils/request";
import { sendErrorNotification } from "../../utils/request/error_handler";
import { UpdateBriefStatus } from "../brief/types";
import { PageMetaDto, PageMetaDtoCrm } from "../common/types";
import {
  GetDocumentResponse,
  GetSubmissionsResponse
} from "../submission/types";
import {
  PageMetaDtoProject,
  ProjectContext,
  ProjectPagination
} from "./project.types";
import { AddCommentDto, Task, TaskPagination } from "../tasks/type";
import { getInvalidatTagsProjects } from "./project.utils";
import {
  Approval,
  ApprovalConfig,
  ApprovalSpec
} from "../approval/approval.type";
import { GetManyDefaultResponse } from "../../utils/api/api.types";
import { downloadBlob } from "../../utils/function/downloadBlob";
import { EntityType } from "../events/type";
import {
  setCustomFieldValues,
  transformErpWNestedCustomFields
} from "../common/utils";
import { extendedApiSliceEvents } from "../events/events.service";
import { Comment } from "../configuratorInputs/configuratorInput.type";
import { CustomFieldValue } from "../customFields/customFieldValues/types";
import { transformProject } from "./transform";
import { MetaEntityHipe } from "../customFields/types";
import { getLocalEntityFilter } from "../../components/common/GenericTable/components/GenericFilterTable";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: [
    "Projects",
    "ProjectSubmissions",
    "ProjectBriefs",
    "ProjectDocuments",
    "ProjectContacts",
    "Submissions",
    "MySubmissions",
    "MyProjects",
    "Project",
    "ProjectTasks",
    "CompanyProjects",
    "ProjectApproval",
    "ProjectApprovalConfig"
  ]
});

export const projectApiSlice = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getProjects: builder.query<ProjectPagination, Partial<PageMetaDto>>({
      query: ({
        page = 1,
        limit = 25,
        status,
        sort,
        search,
        pipeline,
        step,
        manager,
        date,
        start,
        end,
        filterId,
        createdBy
      }) => {
        const crmFilterId =
          filterId ?? getLocalEntityFilter(MetaEntityHipe.Project);
        return dynamicUrl("/projects/pagination", {
          page,
          limit,
          sort,
          status,
          search,
          pipeline,
          manager,
          step,
          date,
          start,
          end,
          filterId,
          createdBy
          //filterId: crmFilterId
        });
      },
      transformResponse: (responseData: ProjectPagination) => {
        return transformProject(responseData);
      },
      providesTags: (result, error, page) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "Projects" as const,
                id
              })),
              { type: "Projects", id: "PARTIAL-LIST" }
            ]
          : [{ type: "Projects", id: "PARTIAL-LIST" }]
    }),
    getOneProject: builder.query<Project, { id: string }>({
      query: ({ id }) => `/projects/${id}`,
      providesTags: (result) => [{ type: "Project", id: result?.id }],
      transformResponse: (project: Project) => {
        return {
          ...project,
          opportunityPipelineId: project?.opportunityPipelineId || undefined,
          customFields: setCustomFieldValues(
            project?.customFields as CustomFieldValue[],
            MetaEntityHipe.Project
          ),
          ...transformErpWNestedCustomFields(project, EntityType.Projects, {
            [EntityType.Companies]: 1
          })
        };
      }
    }),
    getMyProjects: builder.query<ProjectPagination, Partial<PageMetaDtoCrm>>({
      query: ({
        page = 1,
        limit = 25,
        status,
        sort,
        search,
        userId,
        pipeline,
        step,
        companyId,
        filterId
      }) =>
        dynamicUrl(`/users/${userId}/my-projects`, {
          page,
          limit,
          sort,
          status,
          search,
          pipeline,
          step,
          companyId,
          filterId
        }),
      transformResponse: (responseData: ProjectPagination) => {
        return transformProject(responseData);
      },
      providesTags: (result, error, page) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "MyProjects" as const,
                id
              })),
              { type: "MyProjects", id: "PARTIAL-LIST" }
            ]
          : [{ type: "MyProjects", id: "PARTIAL-LIST" }]
    }),
    getProjectsByContact: builder.query<
      ProjectPagination,
      Partial<PageMetaDtoCrm>
    >({
      query: ({
        page = 1,
        limit = 25,
        sort,
        companyId,
        status,
        search,
        contactId,
        filterId
      }) => {
        const url = companyId
          ? `/projects/${companyId}/company/${contactId}/contacts`
          : `/projects/contacts/${contactId}`;
        return dynamicUrl(url, {
          page,
          limit,
          sort,
          status,
          search,
          filterId
        });
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "ProjectContacts" as const,
                id
              })),
              { type: "ProjectContacts", id: "PARTIAL-LIST" }
            ]
          : [{ type: "ProjectContacts", id: "PARTIAL-LIST" }]
    }),
    getProjectsByCompany: builder.query<Project[], string>({
      query: (companyId) => `/companies/${companyId}/projects-all`
    }),
    getPaginatedProjectsByCompany: builder.query<
      ProjectPagination,
      Partial<PageMetaDtoCrm>
    >({
      query: ({
        page = 1,
        limit = 25,
        sort,
        companyId,
        status,
        search,
        filterId
      }) =>
        dynamicUrl(`/companies/${companyId}/projects`, {
          page,
          limit,
          sort,
          status,
          search,
          filterId
        }),
      transformResponse: (responseData: ProjectPagination) => {
        return transformProject(responseData);
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "CompanyProjects" as const,
                id
              })),
              { type: "CompanyProjects", id: "PARTIAL-LIST" }
            ]
          : [{ type: "CompanyProjects", id: "PARTIAL-LIST" }]
    }),
    editProject: builder.mutation<
      Project,
      Partial<Project> & { context?: ProjectContext }
    >({
      query(data) {
        const { id, context, ...patch } = data;
        return {
          url: `/projects/${id}`,
          method: "PATCH",
          body: { ...patch }
        };
      },
      invalidatesTags: (_, __, arg) => {
        if (!arg?.context) return [];
        return getInvalidatTagsProjects(arg.context, arg?.id) as any;
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            new AppAddSnackbar(
              i18n.t("saga:update-success").toString(),
              "success"
            )
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed").toString()
            )
          );
        }
      }
    }),
    addProjectComment: builder.mutation<Comment, AddCommentDto>({
      query(dto) {
        const { id, ...post } = dto;
        return {
          url: `/projects/${id}/comments`,
          method: "POST",
          body: post
        };
      },
      invalidatesTags: (comment, _, __) => {
        return [{ type: "Project", id: comment?.projectId as string }];
      },
      /*   invalidatesTags: (_, __, data) => {
        return [{ type: "Company", id: data?.id as string }];
      }, */
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: comment } = await queryFulfilled;
          //Update cache of all events by comments
          dispatch(
            extendedApiSliceEvents.util.updateQueryData(
              `getProjectEventsByComments`,
              { id: arg?.id },
              (draft: any) => {
                const update = [
                  {
                    ...comment?.events[0],
                    user: comment?.user,
                    comment: { ...comment },
                    task: comment?.task
                  }
                ];
                draft.data = [...update, ...draft?.data];
              }
            )
          );
          dispatch(
            new AppAddSnackbar(
              i18n.t("saga:update-success").toString(),
              "success"
            )
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed").toString()
            )
          );
        }
      }
    }),
    getProjectSubmissions: builder.query<
      GetSubmissionsResponse,
      { id: string; query: string }
    >({
      query: ({ id, query }) => `/projects/${id}/submissions${query}`,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "ProjectSubmissions" as const,
                id
              })),
              { type: "ProjectSubmissions", id: "PARTIAL-LIST" }
            ]
          : [{ type: "ProjectSubmissions", id: "PARTIAL-LIST" }]
    }),
    getProjectBriefs: builder.query<
      GetSubmissionsResponse,
      { id: string; query: string }
    >({
      query: ({ id, query }) => `/projects/${id}/briefs${query}`,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "ProjectBriefs" as const,
                id
              })),
              { type: "ProjectBriefs", id: "PARTIAL-LIST" }
            ]
          : [{ type: "ProjectBriefs", id: "PARTIAL-LIST" }]
    }),
    generateDraft: builder.mutation<Submission, Partial<Submission>>({
      query({ id, formId, ...body }: Partial<Submission>) {
        return {
          url: `projects/${id}/submissions/${formId}`,
          method: "POST",
          body: body
        };
      },
      invalidatesTags: () => [
        { type: "ProjectSubmissions", id: "PARTIAL-LIST" }
      ]
    }),
    updateProjectStatus: builder.mutation<void, UpdateBriefStatus>({
      query({ briefId, statusId }) {
        return {
          url: `/briefs/${briefId}`,
          method: "PATCH",
          body: { statusId }
        };
      },
      invalidatesTags: () => [{ type: "ProjectBriefs", id: "PARTIAL-LIST" }],
      async onQueryStarted(arg, { dispatch }) {
        try {
          dispatch(
            new AppAddSnackbar(i18n.t("saga:update-success"), "success")
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed")
            )
          );
        }
      }
    }),
    editProjectBriefName: builder.mutation<Brief, Partial<Brief>>({
      query(data) {
        const { id, name } = data;
        return {
          url: `/briefs/${id}`,
          method: "PATCH",
          body: { name }
        };
      },
      invalidatesTags: () => [{ type: "ProjectBriefs", id: "PARTIAL-LIST" }],
      async onQueryStarted(arg, { dispatch }) {
        try {
          dispatch(
            new AppAddSnackbar(i18n.t("saga:update-success"), "success")
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed")
            )
          );
        }
      }
    }),
    projectSubmissionUpdateStatus: builder.mutation<
      Submission,
      Partial<Submission>
    >({
      query: ({ id, statusId }) => ({
        url: `submissions/${id}`,
        method: "PATCH",
        body: { statusId }
      }),
      invalidatesTags: () => [
        { type: "ProjectSubmissions", id: "PARTIAL-LIST" },
        { type: "MySubmissions", id: "PARTIAL-LIST" },
        { type: "Submissions", id: "PARTIAL-LIST" }
      ]
    }),
    getProjectDocuments: builder.query<
      GetDocumentResponse,
      { id: string; query: string }
    >({
      query: ({ id, query }) => `/projects/${id}/files${query}`,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "ProjectDocuments" as const,
                id
              })),
              { type: "ProjectDocuments", id: "PARTIAL-LIST" }
            ]
          : [{ type: "ProjectDocuments", id: "PARTIAL-LIST" }]
    }),
    uploadProjectDocument: builder.mutation<
      void,
      {
        projectId: string;
        formData: FormData;
        onUploadProgress: AxiosRequestConfig["onUploadProgress"];
      }
    >({
      queryFn: async (args, { dispatch }): Promise<any> => {
        try {
          const { data } = await Request({
            withToken: true
          }).post(`/projects/${args.projectId}/files`, args.formData, {
            onUploadProgress: args.onUploadProgress
          });
          dispatch(
            new AppAddSnackbar(i18n.t("saga:update-success"), "success")
          );
          return { data };
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed")
            )
          );
          return { error };
        }
      },
      invalidatesTags: (_, __, data) => [
        { type: "ProjectDocuments", id: "PARTIAL-LIST" },
        { type: "Project", id: data?.projectId }
      ]
    }),
    downloadProjectDocument: builder.mutation<
      string,
      { projectId: string; fileId: string; preview: boolean }
    >({
      query: ({ projectId, fileId, preview }) => ({
        url: `/projects/${projectId}/files/${fileId}/signed?preview=${preview}`,
        method: "GET",
        responseHandler: "text"
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          const { data: url } = await queryFulfilled;
          window.open(url, args.preview ? "_blank" : "_self");
          dispatch(new AppAddSnackbar(i18n.t("saga:read-success"), "success"));
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:read-failed")
            )
          );
        }
      }
    }),
    editProjectDocument: builder.mutation<
      string,
      { projectId: string; fileId: string }
    >({
      query({ projectId, fileId, ...patch }) {
        return {
          url: `/projects/${projectId}/files/${fileId}`,
          method: "PATCH",
          body: { ...patch }
        };
      },
      invalidatesTags: () => [{ type: "ProjectDocuments", id: "PARTIAL-LIST" }],
      async onQueryStarted(arg, { dispatch }) {
        try {
          dispatch(
            new AppAddSnackbar(i18n.t("saga:update-success"), "success")
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed")
            )
          );
        }
      }
    }),
    // TODO: Should be move later to a file services
    removeProjectDocument: builder.mutation<
      string,
      { projectId: string; fileId: string }
    >({
      query: ({ projectId, fileId }) => ({
        url: `/projects/${projectId}/files/${fileId}`,
        method: "DELETE"
      }),
      invalidatesTags: (_, __, data) => [
        { type: "ProjectDocuments", id: "PARTIAL-LIST" },
        { type: "Project", id: data?.projectId }
      ],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            new AppAddSnackbar(i18n.t("saga:delete-success"), "success")
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:delete-failed")
            )
          );
        }
      }
    }),
    getTasksByProjectId: builder.query<
      TaskPagination,
      Partial<PageMetaDtoProject>
    >({
      query: ({
        page = 1,
        limit = 25,
        status,
        sort,
        search,
        user,
        taskType,
        date,
        start,
        end,
        projectId,
        filterId
      }) =>
        dynamicUrl(`/projects/${projectId}/tasks`, {
          page,
          limit,
          sort,
          status,
          user,
          taskType,
          date,
          search,
          start,
          end,
          filterId
        }),
      providesTags: (_, __, { projectId }) => [
        { type: "ProjectTasks", id: projectId as string }
      ]
    }),
    createProjectTask: builder.mutation<Task, Partial<Task>>({
      query(dto) {
        return {
          url: "/tasks",
          method: "POST",
          body: dto
        };
      },
      invalidatesTags: (_, __, data) => [
        { type: "ProjectTasks", id: data?.projectId as string },
        { type: "Project", id: data?.projectId as string }
      ],
      async onQueryStarted(arg, { dispatch }) {
        try {
          dispatch(
            new AppAddSnackbar(i18n.t("saga:update-success"), "success")
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed")
            )
          );
        }
      }
    }),
    getProjectApproval: builder.query<
      GetManyDefaultResponse<Approval>,
      { id: string; query: string }
    >({
      query: ({ id, query }) => `/projects/${id}/approvals/files${query}`,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "ProjectApproval" as const,
                id
              })),
              { type: "ProjectApproval", id: "LIST" }
            ]
          : [{ type: "ProjectApproval", id: "LIST" }]
    }),
    getProjectApprovalTemplate: builder.query<any, { id?: string }>({
      query: ({ id }) => `/projects/${id}/approvals/template`,
      providesTags: (result, _err, { id }) =>
        result ? [{ type: "ProjectApprovalConfig" as const, id }] : []
    }),
    initializeProjectApprovals: builder.mutation<
      ApprovalConfig,
      { id: string }
    >({
      query: ({ id }) => `/projects/${id}/approvals/initialize`,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          dispatch(new AppAddSnackbar(i18n.t("saga:create-started"), "info"));
          await queryFulfilled;
          dispatch(
            new AppAddSnackbar(i18n.t("saga:create-success"), "success")
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:create-failed")
            )
          );
        } finally {
          dispatch(
            apiWithTag.util.invalidateTags([
              { type: "ProjectApproval", id: "LIST" },
              { type: "Project", id: args?.id }
            ])
          );
        }
      }
    }),
    downloadApprovalFile: builder.mutation<
      string,
      { id: string; path: string; download?: boolean }
    >({
      query: ({ id, path }) => ({
        url: `/projects/${id}/approvals/download/file/url`,
        params: { path },
        method: "GET",
        responseHandler: "text"
      }),
      onQueryStarted: async (_arg, { queryFulfilled, dispatch }) => {
        try {
          const { data: url } = await queryFulfilled;
          window.open(url, "_blank");
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:read-failed")
            )
          );
        }
      }
    }),
    downloadApprovalReport: builder.mutation<
      string,
      { id: string; path: string }
    >({
      query: ({ id, path }) => ({
        url: `/projects/${id}/approvals/download/report`,
        params: { path },
        method: "GET",
        responseType: "blob"
      }),
      onQueryStarted: async ({ path }, { queryFulfilled, dispatch }) => {
        try {
          /** Fetching issue
           * data: "%PDF-1.4\n%����\n1 0 obj\n<<\n/Type /Catalog\n/Ve
           * error: "SyntaxError: Unexpected token '%', \"%PDF-1.4\n%\"... is not valid JSON"
           * originalStatus: 200
           * status:  "PARSING_ERROR"
           **/
          const res = await queryFulfilled;
          const blob = await res.meta?.response?.blob();
          const nameFile = decodeURIComponent(path.split("/")?.pop() || "");
          const nameWithoutExt = nameFile.split(".").slice(0, -1).join(".");
          const nameReport = nameWithoutExt
            ? `Report_${nameWithoutExt}.pdf`
            : `Approval_Report.pdf`;

          if (!blob) {
            throw new Error("Report fetched is empty");
          }
          downloadBlob(blob, nameReport);
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:read-failed")
            )
          );
        }
      }
    }),
    startApproval: builder.mutation<void, { id: string; approval: Approval }>({
      query({ id, approval }) {
        return {
          url: `/projects/${id}/approvals/start`,
          method: "POST",
          body: { approval }
        };
      },
      invalidatesTags: (_, __, { approval }) => [
        { type: "ProjectApproval", id: approval.id },
        { type: "ProjectApproval", id: "LIST" }
      ],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            new AppAddSnackbar(i18n.t("saga:update-success"), "success")
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed")
            )
          );
        }
      }
    }),
    stopAllApprovals: builder.mutation<void, { id: string }>({
      query({ id }) {
        return {
          url: `/projects/${id}/approvals/stop-all`,
          method: "POST"
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            new AppAddSnackbar(i18n.t("saga:update-success"), "success")
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed")
            )
          );
        } finally {
          setTimeout(() => {
            dispatch(
              apiWithTag.util.invalidateTags([
                { type: "ProjectApproval", id: "LIST" }
              ])
            );
          }, 2000);
        }
      }
    }),
    archiveAllApprovals: builder.mutation<void, { id: string }>({
      query({ id }) {
        return {
          url: `/projects/${id}/approvals/archive-all`,
          method: "POST"
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            new AppAddSnackbar(i18n.t("saga:update-success"), "success")
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:update-failed")
            )
          );
        } finally {
          dispatch(
            apiWithTag.util.invalidateTags([
              { type: "ProjectApproval", id: "LIST" }
            ])
          );
        }
      }
    }),
    addApproval: builder.mutation<
      void,
      { id: string; approvalSpec: ApprovalSpec; callback?: () => void }
    >({
      query({ id, approvalSpec: { filesToUpload, ...spec } }) {
        return {
          url: `/projects/${id}/approvals/add`,
          method: "POST",
          body: spec
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          dispatch(new AppAddSnackbar(i18n.t("saga:create-started"), "info"));
          await queryFulfilled;
          dispatch(
            new AppAddSnackbar(i18n.t("saga:create-success"), "success")
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:create-failed")
            )
          );
        } finally {
          dispatch(
            apiWithTag.util.invalidateTags([
              { type: "ProjectApproval", id: "LIST" }
            ])
          );
          arg.callback?.();
        }
      }
    }),
    uploadApprovalDocument: builder.mutation<
      Approval,
      {
        projectId: string;
        formData: FormData;
        onUploadProgress: AxiosRequestConfig["onUploadProgress"];
      }
    >({
      queryFn: async (args): Promise<any> => {
        try {
          const { data } = await Request({
            withToken: true
          }).post(
            `/projects/${args.projectId}/approvals/upload`,
            args.formData,
            {
              onUploadProgress: args.onUploadProgress
            }
          );
          return { data };
        } catch (error) {
          return { error };
        }
      }
    }),
    archiveProjectBrief: builder.mutation<
      void,
      { id: string; briefId: string }
    >({
      query({ id, briefId }) {
        return {
          url: `projects/${id}/briefs/${briefId}/archive`,
          method: "DELETE"
        };
      },
      invalidatesTags: () => [{ type: "ProjectBriefs", id: "PARTIAL-LIST" }],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        try {
          dispatch(
            new AppAddSnackbar(i18n.t("saga:delete-success"), "success")
          );
        } catch (error) {
          dispatch(
            sendErrorNotification(
              error as AxiosError,
              i18n.t("saga:delete-failed")
            )
          );
        }
      }
    })
  }),
  overrideExisting: false
});

// injectEndpoints to avoid duplicate slice
export const {
  useGetProjectsQuery,
  useGetMyProjectsQuery,
  useGetProjectSubmissionsQuery,
  useGetOneProjectQuery,
  useLazyGetOneProjectQuery,
  useLazyGetProjectsQuery,
  useEditProjectMutation,
  usePrefetch: useProjectRefetch,
  useProjectSubmissionUpdateStatusMutation,
  useGetProjectDocumentsQuery,
  useDownloadProjectDocumentMutation,
  useRemoveProjectDocumentMutation,
  useUploadProjectDocumentMutation,
  useEditProjectDocumentMutation,
  useGenerateDraftMutation,
  useGetProjectBriefsQuery,
  useUpdateProjectStatusMutation,
  useEditProjectBriefNameMutation,
  useGetTasksByProjectIdQuery,
  useCreateProjectTaskMutation,
  useGetProjectApprovalQuery,
  useLazyGetProjectApprovalQuery,
  useInitializeProjectApprovalsMutation,
  useDownloadApprovalFileMutation,
  useDownloadApprovalReportMutation,
  useStartApprovalMutation,
  useAddApprovalMutation,
  useGetProjectApprovalTemplateQuery,
  useStopAllApprovalsMutation,
  useArchiveAllApprovalsMutation,
  useUploadApprovalDocumentMutation,
  useAddProjectCommentMutation,
  useGetProjectsByContactQuery,
  useGetPaginatedProjectsByCompanyQuery,
  useGetProjectsByCompanyQuery,
  useArchiveProjectBriefMutation
} = projectApiSlice;
